import { NetDimensionService } from 'services/net-dimension-service';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { NettingService } from 'services/netting-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';

@autoinject
export class FormNetting {
  @bindable
  private netId: number;
  private netObject: Models.Net;
  private nettings: Array<Models.Netting>;
  private nextTabIndex: number = null;
  private nettingNewFormVisible: boolean = false;
  private nettingEditFormVisible: number = null;
  private locked: boolean = true;
  private originalObject: Models.Net;

  private validationMessages: Array<string>;

  private subscription1: Subscription;
  private subscription2: Subscription;

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private netService: NetService,
    private nettingService: NettingService,
    private netDimensionService: NetDimensionService,
    private router: Router,
    private toastService: ToastService,
    private utility: Utility
  ) {}

  protected activate(params: { NetId: number; Id: number }) {
    this.netId = params.NetId || params.Id;
    this.getNet();

    // Update nettings array when creating new nettings
    this.subscription1 = this.eventAggregator.subscribe('nettingListReset', () => {
      this.nettingEditFormVisible = null;
      this.getNettings();
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    this.subscription2 = this.eventAggregator.subscribe('nettingFormEditClose', () => {
      return this.getNettings();
    });

    this.eventAggregator.subscribe('nettingFormNewClose', () => {
      this.nettingNewFormVisible = false;
    });

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private deactivate() {
    if (this.subscription1) {
      this.subscription1.dispose();
    }
    if (this.subscription2) {
      this.subscription2.dispose();
    }
  }

  updateNetDimension() {
    // weight is always calculated server side for none mørenot nets, so dont save this information
    if (this.netObject.IsMorenotNet) {
      delete this.netObject.NetDimension.Weight;
    }

    this.netDimensionService
      .put(this.netObject.NetDimension, this.netObject.NetDimension.Id)
      .then((res) => {
        this.updateOriginalObject();
        this.toastService.showSuccess('net.updated');

        this.getNet();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  cancel() {
    this.getNet();
  }

  onIsCombiNetChange(isCombiNet: boolean) {
    this.netObject.NetDimension.IsCombiNet = isCombiNet;
  }

  private canDeactivate() {
    if (
      !this.netObject.Locked &&
      ((this.originalObject && !this.utility.areEqual(this.netObject, this.originalObject, true)) ||
        this.nettingNewFormVisible ||
        this.nettingEditFormVisible > 0)
    ) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.subFormOpenHeading',
            message: 'dialog.subFormOpenMessage',
            actions: {
              delete: { enabled: false },
              save: { enabled: false },
              cancel: { enabled: true, t: 'dialog.cancel' },
              dontsave: { enabled: false },
              continue: { enabled: true, t: 'dialog.continue' },
            },
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'continue') {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private getNettingStrengthRule(dimensionClassId: number, halfMeshSize: number): any {
    this.nettingService
      .getNettingStrengthRule(dimensionClassId, halfMeshSize)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        // not much to do if we have an invalid combination, just ignore this
      });
  }

  private getNettings() {
    this.validateNettings();

    this.nettingService
      .getAll(
        '?$expand=NettingType,MeshSize,Placement,NylonType,Material,ThreadType,NettingColor&$filter=NetId eq ' +
          this.netId
      )
      .then((res) => {
        this.nettings = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getNet() {
    this.netService
      .get(this.netId + '?$expand=NetDimension')
      .then((res) => {
        this.getNettings();

        this.netObject = res;

        this.updateOriginalObject();

        this.locked = res.Locked || this.netIsLinkedToAnalysis(res.NetDimension) ? true : false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private validateNettings() {
    this.nettingService.validateNettings(this.netId).then((messages) => {
      this.validationMessages = messages;
    });
  }

  protected async duplicateNetting(id: number) {
    try {
      await this.nettingService.duplicate(id);
      this.toastService.showSuccess('netting.duplicated');
      this.getNettings();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected deleteNetting(id: number) {
    this.nettingService
      .delete(id)
      .then((res) => {
        this.toastService.showSuccess('netting.deleted');
        this.getNettings();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected netIsLinkedToAnalysis(netDimension: Models.NetDimension) {
    if (!netDimension) return false;
    return (
      !!netDimension.AnalysisVariantId ||
      !!netDimension.AnalysisVariant2Id ||
      !!netDimension.AnalysisVariant3Id ||
      !!netDimension.AnalysisVariant4Id ||
      !!netDimension.AnalysisVariant_2Id ||
      !!netDimension.AnalysisVariant2_2Id ||
      !!netDimension.AnalysisVariant3_2Id ||
      !!netDimension.AnalysisVariant4_2Id
    );
  }

  private updateOriginalObject() {
    if (!this.netObject) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.netObject));
  }
}
