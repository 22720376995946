import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { EquipmentService } from 'services/equipment-service';
import { ErrorService } from 'services/error-service';
import { MarkingService } from 'services/marking-service';
import { NetService } from 'services/net-service';
import { NettingService } from 'services/netting-service';
import { ReinforcementService } from 'services/reinforcement-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';

@autoinject
export class FormMisc {
  @bindable public netId: number;
  private net: Models.Net;
  private markings: Array<Models.Marking>;
  private reinforcements: Array<Models.Reinforcement>;
  private equipments: Array<Models.Equipment>;
  private nettings: Array<Models.Netting>;
  private markingNewFormVisible: boolean = false;
  private markingEditFormVisible: number = null;
  private reinforcementNewFormVisible: boolean = false;
  private reinforcementEditFormVisible: number = null;
  private equipmentNewFormVisible: boolean = false;
  private equipmentEditFormVisible: number = null;
  private nextTabIndex: number = null;
  private locked: boolean = true;

  private subscription1: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;
  private subscription4: Subscription;
  private subscription5: Subscription;
  private subscription6: Subscription;
  private subscription7: Subscription;
  private subscription8: Subscription;

  constructor(
    private equipmentService: EquipmentService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private dialogService: DialogService,
    private utility: Utility,
    private netService: NetService,
    private markingService: MarkingService,
    private reinforcementService: ReinforcementService,
    private nettingService: NettingService
  ) {}

  private activate(params) {
    this.netId = params.NetId || params.Id;
    this.getNet(this.netId);
    this.getNettings();

    this.subscription1 = this.eventAggregator.subscribe('markingListReset', (value) => {
      this.markingEditFormVisible = null;
      this.getMarkings();
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    this.subscription2 = this.eventAggregator.subscribe('markingFormEditClose', (value) => {
      this.getMarkings();
    });

    this.subscription3 = this.eventAggregator.subscribe('markingFormNewClose', (value) => {
      this.markingNewFormVisible = false;
    });

    this.subscription4 = this.eventAggregator.subscribe('reinforcementListReset', (value) => {
      this.reinforcementEditFormVisible = null;
      this.getReinforcements();
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    this.subscription5 = this.eventAggregator.subscribe('reinforcementFormEditClose', (value) => {
      this.getReinforcements();
    });

    this.eventAggregator.subscribe('reinforcementFormNewClose', (value) => {
      this.reinforcementNewFormVisible = false;
    });

    this.subscription6 = this.eventAggregator.subscribe('equipmentListReset', (value) => {
      this.equipmentEditFormVisible = null;
      this.getEquipments();
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    this.subscription7 = this.eventAggregator.subscribe('equipmentFormEditClose', (value) => {
      this.getEquipments();
    });

    this.eventAggregator.subscribe('equipmentFormNewClose', (value) => {
      this.equipmentNewFormVisible = false;
    });

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });

    // refresh if locked / unlocked
    this.subscription8 = this.eventAggregator.subscribe('netLocked', (value) => {
      this.getNet(this.netId);
    });
  }

  private deactivate() {
    if (this.subscription1) {
      this.subscription1.dispose();
    }
    if (this.subscription2) {
      this.subscription2.dispose();
    }
    if (this.subscription3) {
      this.subscription3.dispose();
    }
    if (this.subscription4) {
      this.subscription4.dispose();
    }
    if (this.subscription5) {
      this.subscription5.dispose();
    }
    if (this.subscription6) {
      this.subscription6.dispose();
    }
    if (this.subscription7) {
      this.subscription7.dispose();
    }
    if (this.subscription8) {
      this.subscription8.dispose();
    }
  }

  private canDeactivate() {
    if (
      this.markingNewFormVisible ||
      this.markingEditFormVisible > 0 ||
      this.reinforcementNewFormVisible ||
      this.reinforcementEditFormVisible > 0 ||
      this.equipmentNewFormVisible ||
      this.equipmentEditFormVisible > 0
    ) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.subFormOpenHeading',
            message: 'dialog.subFormOpenMessage',
            actions: {
              delete: { enabled: false },
              save: { enabled: false },
              cancel: { enabled: true, t: 'dialog.cancel' },
              dontsave: { enabled: false },
              continue: { enabled: true, t: 'dialog.continue' },
            },
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'continue') {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private getNet(id) {
    this.netService
      .get(id + '?$expand=NetDimension')
      .then((res) => {
        this.net = res;
        this.getMarkings();
        this.getReinforcements();
        this.getEquipments();
        this.locked = res.Locked || this.netIsLinkedToAnalysis(res.NetDimension) ? true : false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getMarkings() {
    this.markingService
      .getAll('?$filter=NetId eq ' + this.netId + '&$expand=MarkingColor,MarkingPlacement,MarkingType')
      .then((res) => {
        this.markings = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteMarking(id) {
    this.markingService
      .delete(id)
      .then((res) => {
        this.toastService.showSuccess('marking.deleted');
        this.getMarkings();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteReinforcement(id) {
    this.reinforcementService
      .delete(id)
      .then((res) => {
        this.toastService.showSuccess('reinforcement.deleted');
        this.getReinforcements();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteEquipment(id) {
    this.equipmentService
      .delete(id)
      .then((res) => {
        this.toastService.showSuccess('equipment.deleted');
        this.getEquipments();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getReinforcements() {
    this.reinforcementService
      .getAll('?$filter=NetId eq ' + this.netId + '&$expand=ReinforcementType,ReinforcementMounting,NettingType')
      .then((res) => {
        this.reinforcements = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getEquipments() {
    this.equipmentService
      .getAll('?$filter=NetId eq ' + this.netId + '&$expand=EquipmentType')
      .then((res) => {
        this.equipments = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getNettings() {
    this.nettingService
      .getAll(
        '?$expand=NettingType,MeshSize,Placement,NylonType,Material,ThreadType,NettingColor&$filter=NetId eq ' +
          this.netId
      )
      .then((res) => {
        this.nettings = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected netIsLinkedToAnalysis(netDimension: Models.NetDimension) {
    if (!netDimension) return false;
    return (
      !!netDimension.AnalysisVariantId ||
      !!netDimension.AnalysisVariant2Id ||
      !!netDimension.AnalysisVariant3Id ||
      !!netDimension.AnalysisVariant4Id ||
      !!netDimension.AnalysisVariant_2Id ||
      !!netDimension.AnalysisVariant2_2Id ||
      !!netDimension.AnalysisVariant3_2Id ||
      !!netDimension.AnalysisVariant4_2Id
    );
  }
}
